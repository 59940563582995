import React from "react";
import { Spin, Icon } from "antd";

const antIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <Spin indicator={antIcon} />
  </div>
);
export default CircularProgress;
