import {
  REQUEST,
  RECEIVED,
  REQUEST_BANNERS,
  RECEIVE_BANNERS,
  ARCHIVE_IDEA,
  DELETE_IDEA,
  STORE_IDEA,
  PUBLISH_IDEA,
  UNPUBLISH_IDEA,
  UPDATE_IDEA,
  RECEIVE_IDEAS,
  SET_THUMBNAIL_AS_FILE,
  SET_THUMBNAIL_AS_URL,
  RECEIVED_USERS,
  RECEIVED_TOOLS,
  RECEIVED_POSITIVE_CHARACTERISTICS,
  RECEIVED_NEGATIVE_CHARACTERISTICS,
  RECEIVED_SITUATIONS,
} from "../../constants/ActionTypes";
import { v4 as uuidv4 } from "uuid";

const INIT_STATE = {
  banners: [],
  ideas: [],
  users: [],
  normingPositiveCharacteristics: [],
  normingNegativeCharacteristics: [],
  normingSituations: [],
  isLoading: false,
  thumbnailAsFile: "",
  thumbnailAsUrl: { imgUrl: "" },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RECEIVED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case REQUEST_BANNERS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RECEIVE_BANNERS: {
      return {
        ...state,
        isLoading: false,
        banners: action.payload,
      };
    }
    case RECEIVE_IDEAS: {
      return {
        ...state,
        isLoading: false,
        ideas: action.payload,
      };
    }

    case RECEIVED_USERS: {
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    }

    case RECEIVED_POSITIVE_CHARACTERISTICS: {
      return {
        ...state,
        isLoading: false,
        normingPositiveCharacteristics: action.payload,
      };
    }

    case RECEIVED_NEGATIVE_CHARACTERISTICS: {
      return {
        ...state,
        isLoading: false,
        normingNegativeCharacteristics: action.payload,
      };
    }

    case RECEIVED_SITUATIONS: {
      return {
        ...state,
        isLoading: false,
        normingSituations: action.payload,
      };
    }

    case RECEIVED_TOOLS: {
      return {
        ...state,
        isLoading: false,
        tools: action.payload,
      };
    }

    case STORE_IDEA: {
      const newIdea = {
        key: uuidv4(),
        ideaTitle: action.payload.title,
        ideaContent: action.payload.content,
        published: action.payload.publishDate.format("MM/DD/YYYY"),
        author: action.payload.author,
        tags: "draft",
        slug: action.payload.slug,
      };

      let updatedIdeaArray = state.ideas.slice();
      updatedIdeaArray.push(newIdea);
      return { ...state, ideas: updatedIdeaArray };
    }

    case UPDATE_IDEA: {
      const ideasArray = state.ideas.slice();
      const ideaIndex = ideasArray.findIndex(
        (banner) => banner.key === action.key
      );
      ideasArray[ideaIndex].ideaContent = action.payload.ideaContent;
      ideasArray[ideaIndex].published = action.payload.publishDate.format(
        "MM/DD/YYYY"
      );
      ideasArray[ideaIndex].ideaTitle = action.payload.title;
      ideasArray[ideaIndex].tags = action.tags;
      ideasArray[ideaIndex].author = action.payload.author;
      ideasArray[ideaIndex].slug = action.payload.slug;
      return {
        ...state,
        ideas: ideasArray,
      };
    }

    case PUBLISH_IDEA: {
      const ideasArray = state.ideas.slice();
      const ideaIndex = ideasArray.findIndex(
        (banner) => banner.key === action.key
      );
      ideasArray[ideaIndex].ideaContent = action.payload.ideaContent;
      ideasArray[ideaIndex].published = action.payload.publishDate.format(
        "MM/DD/YYYY"
      );
      ideasArray[ideaIndex].ideaTitle = action.payload.title;
      ideasArray[ideaIndex].tags = "published";
      ideasArray[ideaIndex].author = action.payload.author;
      ideasArray[ideaIndex].slug = action.payload.slug;
      return {
        ...state,
        ideas: ideasArray,
      };
    }
    case UNPUBLISH_IDEA: {
      const ideasArray = state.ideas.slice();
      const ideaIndex = ideasArray.findIndex(
        (banner) => banner.key === action.key
      );
      ideasArray[ideaIndex].ideaContent = action.payload.ideaContent;
      ideasArray[ideaIndex].published = action.payload.publishDate.format(
        "MM/DD/YYYY"
      );
      ideasArray[ideaIndex].ideaTitle = action.payload.title;
      ideasArray[ideaIndex].tags = "draft";
      ideasArray[ideaIndex].author = action.payload.author;
      ideasArray[ideaIndex].slug = action.payload.slug;
      return {
        ...state,
        ideas: ideasArray,
      };
    }

    case ARCHIVE_IDEA: {
      let ideaToArchive = state.ideas.filter((idea) => {
        return idea.key === action.payload.key;
      });
      ideaToArchive[0].tags = "archived";

      let updatedIdeasArray = state.ideas.filter((idea) => {
        return idea.key !== action.payload.key;
      });

      let updatedArchiveArray = state.archivedIdeas.slice();
      updatedArchiveArray.push(...ideaToArchive);

      return {
        ...state,
        ideas: updatedIdeasArray,
        archivedIdeas: updatedArchiveArray,
      };
    }
    case DELETE_IDEA: {
      let updatedArchiveArray = state.archivedIdeas.filter((idea) => {
        return idea.key !== action.payload.key;
      });

      return {
        ...state,
        archivedIdeas: updatedArchiveArray,
      };
    }
    case SET_THUMBNAIL_AS_FILE: {
      return {
        ...state,
        thumbnailAsFile: action.payload,
      };
    }
    case SET_THUMBNAIL_AS_URL: {
      return {
        ...state,
        thumbnailAsUrl: action.payload,
      };
    }
    default:
      return state;
  }
};
