import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./SamplePage"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}new-user`}
        component={asyncComponent(() => import("./NewUser"))}
      />
      <Route
        path={`${match.url}banner`}
        component={asyncComponent(() => import("./Banner"))}
      />
      <Route
        exact
        path={`${match.url}new-banner`}
        component={asyncComponent(() => import("./NewBanner"))}
      />
      <Route
        path={`${match.url}edit-banner`}
        component={asyncComponent(() => import("./EditBanner"))}
      />
      <Route
        path={`${match.url}ideas`}
        component={asyncComponent(() => import("./Ideas"))}
      />
      <Route
        exact
        path={`${match.url}new-idea`}
        component={asyncComponent(() => import("./NewIdea"))}
      />
      <Route
        path={`${match.url}edit-idea`}
        component={asyncComponent(() => import("./EditIdea"))}
      />
      <Route
        exact
        path={`${match.url}tools`}
        component={asyncComponent(() => import("./Tools"))}
      />
      <Route
        exact
        path={`${match.url}norming-activity`}
        component={asyncComponent(() => import("./NormingActivity"))}
      />
      <Route
        exact
        path={`${match.url}new-tool`}
        component={asyncComponent(() => import("./NewTool"))}
      />
      <Route
        exact
        path={`${match.url}edit-tool`}
        component={asyncComponent(() => import("./EditTool"))}
      />
      <Route
        path={`${match.url}courses`}
        component={asyncComponent(() => import("./Courses"))}
      />
      <Route
        path="/"
        component={asyncComponent(() => import("./SamplePage"))}
      />
    </Switch>
  </div>
);

export default App;
