import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_NAME,
} from "../../constants/ActionTypes";
import { auth, db } from "../../util/Firebase";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUpFirebase = ({ email, password, username }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        return db
          .collection("users")
          .doc(authUser.user.uid)
          .set({ username, email });
      })
      .then(() => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, pyload: error.message });
      });
  };
};

export const userSignInFirebase = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        db.collection("users")
          .doc(result.user.uid)
          .get()
          .then((doc) => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: USER_DATA,
              payload: result.user,
            });
            dispatch({
              type: USER_NAME,
              payload: doc.data().username,
            });
          });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userSignOutFirebase = () => {
  return (dispatch) => {
    auth.signOut().then(() => {
      // dispatch({ type: USER_DATA, payload: null });
      localStorage.removeItem("token");
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    });
  };
};
