import {
  REQUEST_BANNERS,
  RECEIVE_BANNERS,
  RECEIVE_IDEAS,
  RECEIVED_USERS,
  RECEIVED_TOOLS,
  REQUEST,
  RECEIVED,
  SET_THUMBNAIL_AS_FILE,
  SET_THUMBNAIL_AS_URL,
  RECEIVED_POSITIVE_CHARACTERISTICS,
  RECEIVED_NEGATIVE_CHARACTERISTICS,
  RECEIVED_SITUATIONS,
} from "../../constants/ActionTypes";
import { db } from "../../util/Firebase";

export const storeBanner = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .add({
        bannerContent: message.content,
        effective: message.effective.format("MM/DD/YYYY hh:mm A"),
        expiration: message.expiration.format("MM/DD/YYYY hh:mm A"),
        dismissButton: message.dismiss,
        tags: "draft",
      })
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error storing banner - ", err);
      });
  };
};

export const archiveBanner = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .doc(message.key)
      .set({
        bannerContent: message.bannerContent,
        effective: message.effective,
        expiration: message.expiration,
        dismissButton: message.dismissButton,
        tags: "archived",
      })
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error archiving banner - ", err);
      });
  };
};

export const requestStarted = () => {
  return {
    type: REQUEST,
  };
};

export const requestReceived = () => {
  return {
    type: RECEIVED,
  };
};

export const deleteBanner = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .doc(message.key)
      .delete()
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error deleting banner - ", err);
      });
  };
};

export const publishBanner = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .doc(message.key)
      .set({
        bannerContent: message.bannerContent,
        effective: message.effective,
        expiration: message.expiration,
        dismissButton: message.dismissButton,
        tags: "published",
      })
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error publishing banner - ", err);
      });
  };
};

export const unpublishBanner = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .doc(message.key)
      .set({
        bannerContent: message.bannerContent,
        effective: message.effective,
        expiration: message.expiration,
        dismissButton: message.dismissButton,
        tags: "draft",
      })
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error unpublishing banner - ", err);
      });
  };
};

export const updateBanner = (message, key, tags) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .doc(key)
      .set({
        bannerContent: message.content,
        effective: message.effective.format("MM/DD/YYYY hh:mm A"),
        expiration: message.expiration.format("MM/DD/YYYY hh:mm A"),
        dismissButton: message.dismiss,
        tags: tags,
      })
      .then(() => {
        dispatch(fetchBanners());
      })
      .catch((err) => {
        console.log("error updating banner - ", err);
      });
  };
};

export const requestBanners = () => {
  return {
    type: REQUEST_BANNERS,
  };
};

export const receiveBanners = (banners) => {
  return {
    type: RECEIVE_BANNERS,
    payload: banners,
  };
};

export const storeIdea = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .add({
        ideaTitle: message.title,
        ideaContent: message.ideaContent,
        ideaSummary: message.ideaSummary,
        published: message.publishDate.format("MM/DD/YYYY"),
        author: message.author,
        tags: "draft",
        slug: message.slug,
        thumbnail: message.thumbnail,
      })
      .then(() => {
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error storing idea - ", err);
      });
  };
};

export const archiveIdea = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .doc(message.key)
      .set({
        ideaTitle: message.ideaTitle,
        ideaContent: message.ideaContent,
        ideaSummary: message.ideaSummary,
        published: message.published,
        author: message.author,
        tags: "archived",
        slug: message.slug,
        thumbnail: message.thumbnail,
      })
      .then(() => {
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error storing idea - ", err);
      });
  };
};

export const deleteIdea = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .doc(message.key)
      .delete()
      .then(() => {
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error deleting idea - ", err);
      });
  };
};

export const publishIdea = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .doc(message.key)
      .set({
        ideaTitle: message.ideaTitle,
        ideaContent: message.ideaContent,
        ideaSummary: message.ideaSummary,
        published: message.published,
        author: message.author,
        tags: "published",
        slug: message.slug,
        thumbnail: message.thumbnail,
      })
      .then(() => {
        dispatch(requestReceived());
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error storing idea - ", err);
      });
  };
};

export const unpublishIdea = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .doc(message.key)
      .set({
        ideaTitle: message.ideaTitle,
        ideaContent: message.ideaContent,
        ideaSummary: message.ideaSummary,
        published: message.published,
        author: message.author,
        tags: "draft",
        slug: message.slug,
        thumbnail: message.thumbnail,
      })
      .then(() => {
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error storing idea - ", err);
      });
  };
};

export const updateIdea = (message, key, tags) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .doc(key)
      .set({
        ideaTitle: message.title,
        ideaContent: message.ideaContent,
        ideaSummary: message.ideaSummary,
        published: message.publishDate.format("MM/DD/YYYY"),
        author: message.author,
        tags: tags,
        slug: message.slug,
        thumbnail: message.thumbnail,
      })
      .then(() => {
        dispatch(fetchIdeas());
      })
      .catch((err) => {
        console.log("error storing idea - ", err);
      });
  };
};

export const storeTool = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .add({
        name: message.name,
        description: message.description,
        effective: message.publishDate.format("MM/DD/YYYY"),
        cost: message.cost,
        image: message.image,
        env: "draft",
        slug: message.slug,
        slugType: message.slugType,
      })
      .then(() => {
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error storing tool - ", err);
      });
  };
};

export const updateTool = (message, key, env) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .doc(key)
      .set({
        name: message.name,
        description: message.description,
        effective: message.publishDate.format("MM/DD/YYYY"),
        cost: message.cost,
        image: message.image,
        env: env,
        slug: message.slug,
        slugType: message.slugType,
      })
      .then(() => {
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error storing tool - ", err);
      });
  };
};

export const publishTool = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .doc(message.key)
      .set({
        name: message.name,
        description: message.description,
        effective: message.effective,
        cost: message.cost,
        image: message.image,
        env: "published",
        slug: message.slug,
        slugType: message.slugType,
      })
      .then(() => {
        dispatch(requestReceived());
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error storing tool - ", err);
      });
  };
};

export const unPublishTool = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .doc(message.key)
      .set({
        name: message.name,
        description: message.description,
        effective: message.effective,
        cost: message.cost,
        image: message.image,
        env: "draft",
        slug: message.slug,
        slugType: message.slugType,
      })
      .then(() => {
        dispatch(requestReceived());
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error storing tool - ", err);
      });
  };
};

export const archiveTool = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .doc(message.key)
      .set({
        name: message.name,
        description: message.description,
        effective: message.effective,
        cost: message.cost,
        image: message.image,
        env: "archived",
        slug: message.slug,
        slugType: message.slugType,
      })
      .then(() => {
        dispatch(requestReceived());
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error storing tool - ", err);
      });
  };
};

export const deleteTool = (message) => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .doc(message.key)
      .delete()
      .then(() => {
        dispatch(fetchTools());
      })
      .catch((err) => {
        console.log("error deleting tool - ", err);
      });
  };
};

export const fetchBanners = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("banners")
      .get()
      .then((querySnapshot) => {
        const banners = [];
        querySnapshot.forEach((doc) => {
          banners.push({
            key: doc.id,
            bannerContent: doc.data().bannerContent,
            effective: doc.data().effective,
            expiration: doc.data().expiration,
            dismissButton: doc.data().dismissButton,
            tags: doc.data().tags,
          });
        });
        return banners;
      })
      .then((response) => dispatch(receiveBanners(response)))
      .catch((err) => {
        console.log("error retrieving collection - ", err);
      });
  };
};

export const fetchIdeas = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("ideas")
      .get()
      .then((querySnapshot) => {
        const ideas = [];
        querySnapshot.forEach((doc) => {
          ideas.push({
            key: doc.id,
            ideaTitle: doc.data().ideaTitle,
            ideaContent: doc.data().ideaContent,
            ideaSummary: doc.data().ideaSummary,
            published: doc.data().published,
            author: doc.data().author,
            slug: doc.data().slug,
            thumbnail: doc.data().thumbnail,
            tags: doc.data().tags,
          });
        });
        return ideas;
      })
      .then((response) => dispatch(receiveIdeas(response)))
      .catch((err) => {
        console.log("error retrieving collection - ", err);
      });
  };
};

export const fetchTools = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("tools")
      .get()
      .then((querySnapshot) => {
        const tools = [];
        querySnapshot.forEach((doc) => {
          tools.push({
            key: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            effective: doc.data().effective,
            cost: doc.data().cost,
            image: doc.data().image,
            slug: doc.data().slug,
            slugType: doc.data().slugType,
            env: doc.data().env,
          });
        });
        return tools;
      })
      .then((response) => dispatch(receiveTools(response)))
      .catch((err) => {
        console.log("error retrieving collection - ", err);
      });
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("users")
      .get()
      .then((querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
          users.push({
            username: doc.data().username,
            email: doc.data().email,
            uid: doc.id,
          });
        });
        return users;
      })
      .then((response) => dispatch(receivedUsers(response)))
      .catch((err) => {
        console.log("error retrieving users collection - ", err);
      });
  };
};

export const receivedUsers = (users) => {
  return {
    type: RECEIVED_USERS,
    payload: users,
  };
};

export const fetchPositiveCharacteristics = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("characteristics")
      .doc("positive")
      .get()
      .then((doc) => {
        console.log("doc", doc);
        return doc.data().text;
      })
      .then((response) => dispatch(receivedPositiveCharacteristics(response)))
      .catch((err) => {
        console.log("error retrieving positive characteristics - ", err);
      });
  };
};

export const receivedPositiveCharacteristics = (users) => {
  return {
    type: RECEIVED_POSITIVE_CHARACTERISTICS,
    payload: users,
  };
};

export const fetchNegativeCharacteristics = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("characteristics")
      .doc("negative")
      .get()
      .then((doc) => {
        console.log("doc", doc);
        return doc.data().text;
      })
      .then((response) => dispatch(receivedNegativeCharacteristics(response)))
      .catch((err) => {
        console.log("error retrieving negative characteristics - ", err);
      });
  };
};

export const receivedNegativeCharacteristics = (users) => {
  return {
    type: RECEIVED_NEGATIVE_CHARACTERISTICS,
    payload: users,
  };
};

export const fetchSituations = () => {
  return (dispatch) => {
    dispatch(requestStarted());
    return db
      .collection("situations")
      .get()
      .then((querySnapshot) => {
        const array = [];
        querySnapshot.forEach((doc) => {
          array.push({
            situations: doc.data().situations,
            uid: doc.id,
          });
        });
        console.log("array", array);
        return array;
      })
      .then((response) => dispatch(receivedSituations(response)))
      .catch((err) => {
        console.log("error retrieving situations - ", err);
      });
  };
};

export const receivedSituations = (users) => {
  return {
    type: RECEIVED_SITUATIONS,
    payload: users,
  };
};

export const receiveIdeas = (ideas) => {
  return {
    type: RECEIVE_IDEAS,
    payload: ideas,
  };
};

export const setThumbnailAsFile = (file) => {
  return {
    type: SET_THUMBNAIL_AS_FILE,
    payload: file,
  };
};

export const setThumbnailAsUrl = (url) => {
  return {
    type: SET_THUMBNAIL_AS_URL,
    payload: url,
  };
};

export const receiveTools = (tools) => {
  return {
    type: RECEIVED_TOOLS,
    payload: tools,
  };
};
