import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        {/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="users">
              <Link to="/users">
                <i className="icon icon-contacts" />
                <IntlMessages id="sidebar.users" />
              </Link>
            </Menu.Item>
            <Menu.Item key="banner">
              <Link to="/banner">
                <i className="icon icon-megaphone" />
                <IntlMessages id="sidebar.banner" />
              </Link>
            </Menu.Item>
            <Menu.Item key="ideas">
              <Link to="/ideas">
                <i className="icon icon-feedback" />
                <IntlMessages id="sidebar.ideas" />
              </Link>
            </Menu.Item>
            <Menu.Item key="tools">
              <Link to="/tools">
                <i className="icon icon-orders" />
                <IntlMessages id="sidebar.tools" />
              </Link>
            </Menu.Item>
            <Menu.Item key="courses">
              <Link to="/courses">
                <i className="icon icon-orders" />
                Courses
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="normingActivity">
              <Link to="/norming-activity">
                <i className="icon icon-orders" />
                Team Norming Activity
              </Link>
            </Menu.Item> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
