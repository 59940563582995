// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_NAME = "USER_NAME";

//Content const
export const ARCHIVE_BANNER = "ARCHIVE_BANNER";
export const REQUEST = "REQUEST";
export const RECEIVED = "RECEIVED";
export const STORE_BANNER = "STORE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const PUBLISH_BANNER = "PUBLISH_BANNER";
export const UNPUBLISH_BANNER = "UNPUBLISH_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const REQUEST_BANNERS = "REQUEST_BANNERS";
export const RECEIVE_BANNERS = "RECEIVE_BANNERS";

export const ARCHIVE_IDEA = "ARCHIVE_IDEA";
export const STORE_IDEA = "STORE_IDEA";
export const DELETE_IDEA = "DELETE_IDEA";
export const PUBLISH_IDEA = "PUBLISH_IDEA";
export const UNPUBLISH_IDEA = "UNPUBLISH_IDEA";
export const UPDATE_IDEA = "UPDATE_IDEA";
export const RECEIVE_IDEAS = "RECEIVE_IDEAS";
export const RECEIVED_USERS = "RECEIVED_USERS";

export const RECEIVED_TOOLS = "RECEIVED_TOOLS";

export const SET_THUMBNAIL_AS_FILE = "SET_THUMBNAIL_AS_FILE";
export const SET_THUMBNAIL_AS_URL = "SET_THUMBNAIL_AS_URL";

export const RECEIVED_POSITIVE_CHARACTERISTICS =
  "RECEIVED_POSITIVE_CHARACTERISTICS";
export const RECEIVED_NEGATIVE_CHARACTERISTICS =
  "RECEIVED_NEGATIVE_CHARACTERISTICS";
export const RECEIVED_SITUATIONS = "RECEIVED_SITUATIONS";
export const STORE_POSITIVE_CHARACTERISTICS = "STORE_POSITIVE_CHARACTERISTICS";
export const STORE_NEGATIVE_CHARACTERISTICS = "STORE_NEGATIVE_CHARACTERISTICS";
export const STORE_SITUATIONS = "STORE_SITUATIONS";
