// import app from "firebase/app";
// import "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyCEeMtWZTkioEC195goMa05BYOUQ25Lsc8",
//   authDomain: "mjif-33873.firebaseapp.com",
//   databaseURL: "https://mjif-33873.firebaseio.com",
//   projectId: "mjif-33873",
//   storageBucket: "mjif-33873.appspot.com",
//   messagingSenderId: "972855732200",
//   appId: "1:972855732200:web:786405b2147a5ee5bea65d"
// };

// class Firebase {
//   constructor() {
//     app.initializeApp(firebaseConfig);
//     this.auth = app.auth();
//   }
//   // *** Auth API ***
//   doCreateUserWithEmailAndPassword = (email, password) =>
//     this.auth.createUserWithEmailAndPassword(email, password);

//   doSignInWithEmailAndPassword = (email, password) =>
//     this.auth.signInWithEmailAndPassword(email, password);

//   doSignOut = () => this.auth.signOut();

//   doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

//   doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
// }

// export const auth = firebase.auth();
// export default Firebase;

// import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCEeMtWZTkioEC195goMa05BYOUQ25Lsc8",
  authDomain: "mjif-33873.firebaseapp.com",
  databaseURL: "https://mjif-33873.firebaseio.com",
  projectId: "mjif-33873",
  storageBucket: "mjif-33873.appspot.com",
  messagingSenderId: "972855732200",
  appId: "1:972855732200:web:6ba696bf956fabccbea65d",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export default firebase;
